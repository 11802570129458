import './index.scss'

//点击关闭按钮，关闭登录弹窗
$('.login-dialog .login-dialog-close').on('click', function () {
    $('.login-dialog').fadeOut(200)
    $('.root-mask').fadeOut(200)
})

//点击微信登录,显示二维码
$('.login-cont .wx-login-button-log').on('click', function () {
    if (window.screen.width > 640) {
        // 电脑端微信登录，扫码
        $('.login-cont').hide()
        $('.wx-login-cont').show()
        //这里写生成二维码的js代码，目前使用一张图片占位

    } else {
        // 手机端微信登录
    }
})
// 20230315 切换功能要放到外面，正式版有其他功能 开始
//点击密码登录时
// $('.login-cont .passwordlogin').on('click', function () {
//     $(this).hide()
//     $('.login-cont .yanzmlogin').show()
//     $('.login-cont .common-form-item-yzm').hide()
//     $('.login-cont .common-form-item-mima').show()
// })
// //点击验证码登录时
// $('.login-cont .yanzmlogin').on('click', function () {
//     $(this).hide()
//     $('.login-cont .passwordlogin').css({'display':'flex'})
//     $('.login-cont .common-form-item-yzm').show()
//     $('.login-cont .common-form-item-mima').hide()
// })
// 20230315 切换功能要放到外面，正式版有其他功能 结束


//微信点击返回按钮，返回账号密码登录
$('.wx-login-cont .backAccount').on('click', function () {
    $('.wx-login-cont').hide()
    $('.login-cont').show()
})

// 点击获取验证码 倒计时
// $('.login-cont .yzmDuan').on('click', function () {
//     settimes(this);
// })
// var countdown = 60;
// function settimes(obj) {
//     if (countdown == 0) {
//         obj.removeAttribute("disabled");
//         obj.innerHTML = "重新获取";
//         $(".yzmDuan").removeClass("click-cur")
//         countdown = 60;
//         return;

//     } else {
//         obj.setAttribute("disabled", true);
//         obj.innerHTML = "" + countdown + "s";
//         countdown--;
//         $(".yzmDuan").addClass("click-cur")
//     }
//     setTimeout(function () {
//         settimes(obj)
//     }, 1000)

// }
//仅供效果查看，不是正式逻辑代码
//点击二维码下的提示，显示扫码成功提示
// $('.wx-login-cont .wx-login-hint .hint1').on('click', function () {
//   $('.wx-login-cont .wx-login-hint').addClass('active')
// })

//仅供效果查看，不是正式逻辑代码
//点击二维码下的成功提示，显示绑定表单
// $('.wx-login-cont .wx-login-hint .hint2').on('click', function () {
//   $('.wx-login-cont').hide()
//   $('.bind-tel').show()
// })
