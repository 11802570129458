module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '\r\n<!-- 个人中心banner -->\r\n<section class="person-banner">\r\n  <div class="container-fluid container-1200">\r\n    <div class="left">\r\n      <div class="img"><img src="' +
((__t = (require('./images/index_author5.jpg'))) == null ? '' : __t) +
'" alt=""></div>\r\n      <div class="text">\r\n        <span>欢迎回来，</span>\r\n        <span class="name">韩员工</span>\r\n      </div>\r\n    </div>\r\n    <div class="right">\r\n      <a href="staffIndex.html"><i class="iconfont icon-xiaohuake"></i>消化科</a>\r\n      <a href="staffIndex.html"><i class="iconfont icon-respiratory_medicine"></i>呼吸科</a>\r\n      <a href="staffIndex.html"><i class="iconfont icon-data-"></i>员工专区</a>\r\n    </div>\r\n  </div>\r\n</section>';

}
return __p
}