import './index.scss'
import '../../assets/scripts/common.js'


//点击打开收起搜索区域
$('[data-open="headerSearch"]').on('click', function () {
    if ($(this).hasClass('search-show')){
        $(this).removeClass('search-show')
        $('.header-search').removeClass('active')
        $('.root-mask-bottom').fadeOut(200)
        $('html,body').css({
          "overflow-x": "auto",
          "overflow-y": "auto"
        });
    }else{
        $(this).addClass('search-show')
        $('.header-search').addClass('active')
        $('.root-mask-bottom').fadeIn(200)
        $('html,body').css({
          "overflow-x": "hidden",
          "overflow-y": "hidden"
        });
    } 
})
//搜索输入框获取焦点时，显示搜索历史
$(".header-container .header-search .search-form .search-form-input input").focus(function () {
  $(".header-container .header-search .search-form .search-history").show()
}).blur(function(){
  setTimeout(() => {
    $(".header-container .header-search .search-form .search-history").hide()
  }, 200);
});
// 点击遮罩收起搜索层
$('.root-mask-bottom').on('click',function(){
  if ($('.header-search').hasClass('active')){
    $('[data-open="headerSearch"]').removeClass('search-show')
    $('.header-search').removeClass('active')
    $('.root-mask-bottom').fadeOut(200)
    $('html,body').css({
      "overflow-x": "auto",
      "overflow-y": "auto"
    });
  }
})

//点击登录，打开登录弹窗
$('[data-open="logindialog"]').on('click', function () {
  $('.login-dialog').fadeIn(200).css('display', 'flex')
  $('.login-dialog .login-cont').show()
  $('.login-dialog .wx-login-cont,.login-dialog .bind-tel').hide()
  $('.root-mask').fadeIn(200)

  //显示已登陆，仅用于效果查看
  // $('.comp-root-header .user-icon').addClass('islogin')
  // $('.footer .foot-login').hide()
  // $('.footer-zhanwei').height($('.footer').height())
})

// 点击打开手机导航栏
// $('.header-container [data-open="cmenu"]').on('click', function () {
//     if ($('.header-container .hamburger').hasClass('active')) {
//       $('.header-container .hamburger').removeClass('active')
//       $('.root-mask-bottom').fadeOut(200)
//       $('.header-container .header-nav-mob').removeClass('active')
//       $('.header-container .header-nav-mob ul li .sub-nav').stop().slideUp(200)
//       $('.header-container .header-nav-mob ul li .sub-nav-title').removeClass('active')
//     } else {
//       $('.header-container .hamburger').addClass('active')
//       $('.root-mask-bottom').fadeIn(200)
//       $('.header-container .header-nav-mob').addClass('active')
//     }
// })
//点击手机导航列表展开子项
// $('.header-nav-mob ul li .sub-nav-title').on('click', function () {
//   if (!$(this).hasClass('active')) {
//     $('.header-nav-mob ul li .sub-nav-title').removeClass('active')
//     $(this).addClass('active')
//     $('.header-nav-mob ul li .sub-nav').stop().slideUp(200)
//     $(this).siblings('.sub-nav').stop().slideDown(200)
//   }
// })
