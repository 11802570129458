import './index.scss'
import '../../assets/scripts/common.js'


// 点击打开手机导航栏
// $('.comp-root-staff-header .header-top [data-open="cmenu"]').on('click', function () {
//   if ($('.comp-root-staff-header .header-top .hamburger').hasClass('active')) {
//     $('.comp-root-staff-header .header-top .hamburger').removeClass('active')
//     $('.root-mask-bottom').fadeOut(200)
//     $('.comp-root-staff-header .header-nav-mob').removeClass('active')
//     $('.comp-root-staff-header .header-nav-mob ul li .sub-nav').stop().slideUp(200)
//     $('.comp-root-staff-header .header-nav-mob ul li .sub-nav-title').removeClass('active')
//   } else {
//     $('.comp-root-staff-header .header-top .hamburger').addClass('active')
//     $('.root-mask-bottom').fadeIn(200)
//     $('.comp-root-staff-header .header-nav-mob').addClass('active')
//   }
// })
// $('.staff-header-container [data-open="cmenu"]').on('click', function () {
//   if ($('.staff-header-container .hamburger').hasClass('active')) {
//     $('.staff-header-container .hamburger').removeClass('active')
//     $('.root-mask-bottom').fadeOut(200)
//     $('.staff-header-container .header-nav-mob').removeClass('active')
//     $('.staff-header-container .header-nav-mob ul li .sub-nav').stop().slideUp(200)
//     $('.staff-header-container .header-nav-mob ul li .sub-nav-title').removeClass('active')
//   } else {
//     $('.staff-header-container .hamburger').addClass('active')
//     $('.root-mask-bottom').fadeIn(200)
//     $('.staff-header-container .header-nav-mob').addClass('active')
//   }
// })
//点击手机导航列表展开子项
// $('.comp-root-staff-header .header-nav-mob ul li .sub-nav-title').on('click', function () {
//     if(!$(this).hasClass('active')){
//         $('.comp-root-staff-header .header-nav-mob ul li .sub-nav-title').removeClass('active')
//         $(this).addClass('active')
//         $('.comp-root-staff-header .header-nav-mob ul li .sub-nav').stop().slideUp(200)
//         $(this).siblings('.sub-nav').stop().slideDown(200)
//     }
// })

//手机端跳转显示
// $('.comp-root-staff-header .header-top .link-mob .now-link').on('click', function () {
//   let hasActive = $('.comp-root-staff-header .header-top .link-mob').hasClass('active')
//   console.log(hasActive)
//   if (!hasActive) {
//     $('.comp-root-staff-header .header-top .link-mob').addClass('active')
//   }
// })
// $(document).on('click', function (e) {
//   console.log(e)
//    if (($(e.target).attr('class') != 'now-link')) {
//      let hasActive = $('.comp-root-staff-header .header-top .link-mob').hasClass('active')
//      if (hasActive) {
//        $('.comp-root-staff-header .header-top .link-mob').removeClass('active')
//      }
//    }
// })

// $(".staff-header-container .header-nav-pc ul li").mouseenter(function () {
//     console.log('lll')
//   if (!$(this).hasClass('active')) {
//     $(this).children('a').find('b').css('width', '100%');
//     $(this).siblings().children('a').find('b').css('width', '0');
//   }
// });
// $(".staff-header-container .header-nav-pc ul li").mouseleave(function () {
//   if (!$(this).hasClass('active')) {
//     $('.staff-header-container .header-nav-pc ul li.active').children('a').find('b').css('width', '100%');
//     $('.staff-header-container .header-nav-pc ul li.active').siblings().children('a').find('b').css('width', '0');
//   }
// });


//点击打开收起搜索区域
$('[data-open="staffSearch"]').on('click', function () {
  if ($(this).hasClass('search-show')) {
    $(this).removeClass('search-show')
    $('.header-search').removeClass('active')
    $('.root-mask-bottom').fadeOut(200)
    $('html,body').css({
      "overflow-x": "auto",
      "overflow-y": "auto"
    });
  } else {
    $(this).addClass('search-show')
    $('.header-search').addClass('active')
    $('.root-mask-bottom').fadeIn(200)
    $('html,body').css({
      "overflow-x": "hidden",
      "overflow-y": "hidden"
    });
  }
})
//搜索输入框获取焦点时，显示搜索历史
// $(".staff-header-container .header-search .search-form .search-form-input input").focus(function () {
//   $(".staff-header-container .header-search .search-form .search-history").show()
// }).blur(function () {
//   setTimeout(() => {
//     $(".staff-header-container .header-search .search-form .search-history").hide()
//   }, 200);
// });