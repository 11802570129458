import './index.scss'

!function () {
    // getFooterHeight()
}()

//监听浏览器大小改变
$(window).resize(function () {
  // getFooterHeight()
});

//计算footer占位符的高度(footer设置为绝对定位，防止页面太短，footer偏上)
function getFooterHeight() {
    // $('.footer-zhanwei').height($('.footer').height())
}

//返回顶部
$('.backTop').on('click',function () {
  $('html ,body').animate({scrollTop: 0}, 300);
  return false;
})

//手机端底部链接展开收回
$('.footer-top .right .right-item h1').on('click',function(){
  if (window.screen.width<=992 && !$(this).hasClass('active')){
    $('.footer-top .right .right-item h1').removeClass('active')
    $(this).addClass('active')
    $(this).parents('.right-item').find('div').slideDown(200)
    $(this).parents('.right-item').siblings().find('div').slideUp(200)
  } else if(window.screen.width <= 992 && $(this).hasClass('active')){
    $(this).removeClass('active')
    $(this).parents('.right-item').find('div').slideUp(200)
  }
})

// 浮动表单显示隐藏
// $('.fixed-form-cont .fixed-form-btn').on('click',function () {
//   $('.fixed-form-cont .fixed-form-show').fadeIn(200)
// })
// $('.fixed-form-cont .fixed-form .form-close').on('click', function () {
//   $('.fixed-form-cont .fixed-form-show').fadeOut(200)
// })