import './index.scss'

// $('.apply-dialog .close-icon').on('click',function(){
//     $('.apply-dialog').fadeOut(200)
//     $('.root-mask').fadeOut(200)
//     setTimeout(() => {
//         $('.apply-dialog').removeClass('success')
//     }, 200);
// })

// $('.apply-dialog button').on('click',function(e){
//     e.preventDefault();
//     $('.apply-dialog').addClass('success')
// })