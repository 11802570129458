// import './message.js';


// 公共部分js
//禁用右键和文本选择功能
$('img').bind("contextmenu", function () {
  return false;
});
$(document).bind("selectstart", function () {
  return false;
});

//页面滚动监听
$(document).scroll(function () {
  let scroH = $(document).scrollTop(); //滚动高度
  let viewH = $(window).height(); //可见高度
  
  //距离顶部大于50px时,header高度缩小
  if (scroH >= 50 && window.screen.width > 640) { 
    $('.comp-root-header,.comp-root-staff-header').addClass('show-scolltop')
  } else {
    $('.comp-root-header,.comp-root-staff-header').removeClass('show-scolltop')
  }

   //距离顶部大于500px时，显示返回顶部按钮
  if (scroH >= 500) {
    $('.backTop').fadeIn(200)
    
  } else {
    $('.backTop').fadeOut(200)
  }

  let contentH = $(document).height(); //内容高度
  let copyrightH = $('.footer .footer-copyright-cont').height(); //copyright高度
  let backtopH = $('.backTop').height(); //返回按钮高度
  //滚动到底部时 固定返回按钮位置
  if (contentH - (scroH + viewH) <= copyrightH - backtopH) { //距离底部高度小于100px
    $('.backTop').css('bottom', copyrightH + 'px')
  }else{
    $('.backTop').css('bottom', '50px')
  }

  // 滚动距离大于面包屑的高度时，移动端二级菜单悬浮
  let breadcrumbsH = $('.comp-root-breadcrumbs').height(); //面包屑高度
  if (scroH >= breadcrumbsH) { 
    $('.comp-root-mobSubmenu').addClass('fixed');
  } else {
    $('.comp-root-mobSubmenu').removeClass('fixed');
  }

});

// 点击打开手机导航栏
$('[data-open="cmenu"]').on('click', function () {
  if ($('.hamburger').hasClass('active')) {
    $('.hamburger').removeClass('active')
    $('.root-mask-bottom').fadeOut(200)
    $('.header-nav-mob').removeClass('active')
    $('.header-nav-mob ul li .sub-nav').stop().slideUp(200)
    $('.header-nav-mob ul li .sub-nav-title').removeClass('active')
  } else {
    $('.hamburger').addClass('active')
    $('.root-mask-bottom').fadeIn(200)
    $('.header-nav-mob').addClass('active')
  }
})
//点击手机导航列表展开子项
$('.header-nav-mob ul li .sub-nav-title').on('click', function () {
  if (!$(this).hasClass('active')) {
    $('.header-nav-mob ul li .sub-nav-title').removeClass('active')
    $(this).addClass('active')
    $('.header-nav-mob ul li .sub-nav').stop().slideUp(200)
    $(this).siblings('.sub-nav').stop().slideDown(200)
  }else{
    $(this).removeClass('active')
    $('.header-nav-mob ul li .sub-nav').stop().slideUp(200)
  }
})

$('.user-icon').on('click', function () {
  if ($(this).hasClass('islogin') && window.screen.width <= 1024) {
    let hasActive = $('.user-icon.islogin').hasClass('show-subname')
    if (hasActive) {
      $('.user-icon.islogin').removeClass('show-subname')
    } else {
      $('.user-icon.islogin').addClass('show-subname')
    }
  }
})

//表单验证码 点击获取验证码后 60s倒计时
// $('.common-form .yzm-cont .yzm-botton').on('click', function () {
//   $(this).parents('.yzm-cont').addClass('count-down');
//   let TimeEl = $(this).parents('.yzm-cont').find('.yzm-count-down span');
//   let setTime;
//   let time = parseInt($(TimeEl).text());
//   setTime = setInterval(function () {
//     if (time <= 0) {
//       clearInterval(setTime);
//       $('.yzm-cont').removeClass('count-down');
//       $(TimeEl).text(60);
//       return;
//     }
//     time--;
//     $(TimeEl).text(time);
//   }, 1000);
// })

//员工专区线下资料搜索区域 模拟下拉框
$('.staff-offline-search .search-select #offline-select-input').on('click', function (event) {
  let hasActive = $('.staff-offline-search .search-select').hasClass('active')
  if (!hasActive) {
    $('.staff-offline-search .search-select').addClass('active')
  }else{
    $('.staff-offline-search .search-select').removeClass('active')
  }
})
$('.staff-offline-search .search-select .select-option ul li').on('click', function () {
  let value = $(this).html()
  $('.staff-offline-search .search-select input').val(value);
})
$(document).on('click', function (e) {
  if (($(e.target).attr('id') != 'offline-select-input')) {
    let hasActive = $('.staff-offline-search .search-select').hasClass('active')
    if (hasActive) {
      $('.staff-offline-search .search-select').removeClass('active')
    }
  }
})

//员工专区header手机端跳转显示
$('.comp-root-staff-header .header-top .link-mob .now-link').on('click', function () {
  let hasActive = $('.comp-root-staff-header .header-top .link-mob').hasClass('active')
  if (!hasActive) {
    $('.comp-root-staff-header .header-top .link-mob').addClass('active')
  }else{
    $('.comp-root-staff-header .header-top .link-mob').removeClass('active')
  }
})
$(document).on('click', function (e) {
  if (($(e.target).attr('class') != 'now-link')) {
    let hasActive = $('.comp-root-staff-header .header-top .link-mob').hasClass('active')
    if (hasActive) {
      $('.comp-root-staff-header .header-top .link-mob').removeClass('active')
    }
  }
})


$(".staff-header-container .header-nav-pc ul li").mouseenter(function () {
  if (!$(this).hasClass('active')) {
    $(this).children('a').find('b').css('width', '100%');
    $(this).siblings().children('a').find('b').css('width', '0');
  }
});
$(".staff-header-container .header-nav-pc ul li").mouseleave(function () {
  if (!$(this).hasClass('active')) {
    $('.staff-header-container .header-nav-pc ul li.active').children('a').find('b').css('width', '100%');
    $('.staff-header-container .header-nav-pc ul li.active').siblings().children('a').find('b').css('width', '0');
  }
});


//视频弹窗点击按钮，随鼠标移动而移动
var screenWidth = window.screen.width
!(function () {
  if (screenWidth > 1024) {
    $('[data-open="video-dialog"]').on('mousemove', function (e) {
      $('.cursor-box').css({ 'top': e.clientY + 'px', 'left': e.clientX + 'px' });
    })
    $('[data-open="video-dialog"]').on('mouseenter', function () {
      $('.cursor-box').fadeIn(200)
    })
    $('[data-open="video-dialog"]').on('mouseleave', function () {
      $('.cursor-box').fadeOut(200)
    })
  }
})()

//点击视频弹窗按钮,弹出视频窗口
//播放视频
var player;
$('[data-open="video-dialog"]').on('click', function () {
  let video_id = $(this).data('id')
  console.log(video_id)

  let html = `<div class="play_video" id='plv_${video_id}'></div>`
  $('.common-video-play-dialog .video-cont').html(html)

  player = polyvPlayer({
    'wrap': '#plv_' + video_id,
    'width': '100%',
    'height': '0',
    'vid': video_id,
    'autoplay': true,
    'mutedAutoplay': true,
    'volume': 20,
    'playsafe': '' // 播放加密视频的凭证, 取值参考文档: http://dev.polyv.net/?p=16673 
  });

  $('.root-mask').fadeIn(200)
  $('.common-video-play-dialog').fadeIn(200)

  let videoW = $('.common-video-play-dialog').width()
  let videoH = videoW * 0.562

  setTimeout(() => {
    // $('.pv-video-player').css('height', videoH)
    $('div[id^="plv_"]').children('div').css('height', videoH)
    if (window.screen.width >= 1024) {
      $('div[id^="plv_"]').children('div').css('max-height', '585px')
    } else {
      $('div[id^="plv_"]').children('div').css('max-height', '400px')
    }
  }, 100);
  setTimeout(() => {
    // $('.pv-video-player').css('height', videoH)
    $('div[id^="plv_"]').children('div').css('height', videoH)
    if (window.screen.width >= 1024) {
      $('div[id^="plv_"]').children('div').css('max-height', '585px')
    } else {
      $('div[id^="plv_"]').children('div').css('max-height', '400px')
    }
  }, 300);
})

$('.common-video-play-dialog .video-play-close').on('click', function () {
  $('.common-video-play-dialog').fadeOut(200)
  $('.root-mask').fadeOut(200)
  player.destroy()
})
$('.root-mask').on('click', function () {
  $('.common-video-play-dialog').fadeOut(200)
  $('.root-mask').fadeOut(200)
  $('.application-dialog').fadeOut(200)
  $('.login-dialog').fadeOut(200)
  $('.course-plan').fadeOut(200)
  player.destroy()
})

// 点击复制
$(".order .copy").click(function () {
  var text = $(this).prev('.express-no').text();//获取要复制的文本
  //不同的对象获取方式不同可能是text()也可能是html()
  copyText(text);
})
function copyText (text) {//拷贝文本函数
  var oInput = document.createElement('input');//创建一个input标签
  oInput.value = text;//设置value属性
  document.body.appendChild(oInput);//挂载到body下面
  oInput.select(); // 选择对象
  document.execCommand("Copy"); // 执行浏览器复制命令
  oInput.className = 'oInput';
  oInput.style.display = 'none';
  alert('复制成功');
}