module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<section class="shopping_header">\r\n    <div class="container" style="background:url(' +
((__t = (require('./images/shop_bg.png'))) == null ? '' : __t) +
')no-repeat;background-size:cover;">\r\n        <div class="left">\r\n            <div class="head_icon">\r\n                <img src="' +
((__t = ( require('./images/shop_head.png') )) == null ? '' : __t) +
'" alt="">\r\n            </div>\r\n            <div class="text">\r\n                <p>我的积分：</p>\r\n                <p><span class="my-integral">300分</span>(积分永久有效)</p>\r\n                <p>\r\n                    <a href="pointDetailsAll.html"><i class="iconfont icon-jifenmingxi"></i><span>积分明细</span></a>\r\n                    <a href="integralRule.html"><i class="iconfont icon-tixing"></i><span>积分规则</span></a>\r\n                </p>\r\n            </div>\r\n        </div>\r\n        <div class="right">\r\n            <a href="shopExchange.html"><i class="iconfont icon-tubiao_shangcheng"></i>商城首页</a>\r\n            <a href="integralOrder.html"><i class="iconfont icon-quanbudingdan01"></i>全部订单<span>(10)</span></a>\r\n            <a href="integralDeliver.html"><i class="iconfont icon-daifahuo"></i>待发货<span>(10)</span></a>\r\n            <a href="integralReceive.html"><i class="iconfont icon-daishouhuo"></i>待收货<span>(10)</span></a>\r\n            <a href="receiveAddressList.html"><i class="iconfont icon-shouhuodizhi"></i>收货地址</a>\r\n        </div>\r\n    </div>\r\n</section>';

}
return __p
}