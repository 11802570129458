import './index.scss'

// 关闭弹窗
// $('.apply-check-dialog .close-icon').on('click',function(){
//     $('.apply-check-dialog').fadeOut(200)
//     $('.root-mask').fadeOut(200)
// })

//点击审核失败，显示表单
$(document).on('click', '.item-status .btn-fail',function(){
    $(this).addClass('active').parents('.item-status').find('.status-form').addClass('active')
})