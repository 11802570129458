module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '\r\n';

if (isThree === undefined) var isThree = false;
;
__p += '\r\n<!-- 默认一行四列，加 col-three 变为三列 -->\r\n<div class="imglist ' +
((__t = ( isThree ? 'col-three' : '' )) == null ? '' : __t) +
'">\r\n  <ul>\r\n    <li><a href="collectionArticleList-JSJM.html">\r\n        <div class="item-img">\r\n          <img src="' +
((__t = (require('./images/clt_img1.jpg'))) == null ? '' : __t) +
'" alt="">\r\n        </div>\r\n        <div class="item-text">\r\n          <div class="title">镜善镜美</div>\r\n          <div class="desc desctitles">#消化道早癌课程</div>\r\n          <div class="desc">早诊早治 镜善镜美 — 手术视频回顾，是富士胶片早癌诊治系列活动。</div>\r\n        </div>\r\n      </a></li>\r\n    <li><a href="collectionArticleList.html">\r\n        <div class="item-img"><img src="' +
((__t = (require('./images/clt_img2.jpg'))) == null ? '' : __t) +
'" alt=""></div>\r\n        <div class="item-text">\r\n          <div class="title">镜彩汇</div>\r\n          <div class="desc">早诊早治 镜善镜美 — 手术视频回顾，是富士胶片早癌诊治系列活动是富士胶片早癌诊治系列活动。</div>\r\n        </div>\r\n      </a></li>\r\n    <li><a href="collectionArticleList-LCI.html">\r\n        <div class="item-img"><img src="' +
((__t = (require('./images/clt_img3.jpg'))) == null ? '' : __t) +
'" alt=""></div>\r\n        <div class="item-text">\r\n          <div class="title">LCI俱乐部</div>\r\n          <div class="desc">早诊早治 镜善镜美 — 手术视频回顾，是富士胶片早癌诊治系列活动。</div>\r\n        </div>\r\n      </a></li>\r\n    <li><a href="collectionArticleList.html">\r\n        <div class="item-img"><img src="' +
((__t = (require('./images/clt_img5.jpg'))) == null ? '' : __t) +
'" alt=""></div>\r\n        <div class="item-text">\r\n          <div class="title">CAP</div>\r\n          <div class="desc">早诊早治 镜善镜美 — 手术视频回顾，是富士胶片早癌诊治系列活动。</div>\r\n        </div>\r\n      </a></li>\r\n    <li><a href="collectionArticleList.html">\r\n        <div class="item-img">\r\n          <img src="' +
((__t = (require('./images/clt_img6.jpg'))) == null ? '' : __t) +
'" alt="">\r\n          <div class="tag">#消化道早癌课程</div>\r\n        </div>\r\n        <div class="item-text">\r\n          <div class="title">双囊领路</div>\r\n          <div class="desc">早诊早治 镜善镜美 — 手术视频回顾，是富士胶片早癌诊治系列活动。</div>\r\n        </div>\r\n      </a></li>\r\n\r\n      <!-- 占位 勿删，以免排版错乱-->\r\n      <div class="placeholder"></div>\r\n      <div class="placeholder"></div>\r\n      <div class="placeholder"></div>\r\n  </ul>\r\n</div>\r\n';

}
return __p
}