module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '\r\n';

if (!title) var title = '学术课堂';
if (isRed === undefined) var isRed = false;
if (isNoTags === undefined) var isNoTags = false;
if (isShowBig === undefined) var isShowBig = false;
;
__p += '\r\n\r\n<!-- 默认绿色主题，加 red-theme 变为红色主题 -->\r\n<!-- 默认显示tags，加 no-tags 隐藏tags -->\r\n<!-- 默认隐藏左侧大标题，加 big-show 显示左侧大标题 -->\r\n<div class="text-list-full ' +
((__t = ( isRed ? 'red-theme' : '' )) == null ? '' : __t) +
' ' +
((__t = ( isNoTags ? 'no-tags' : '' )) == null ? '' : __t) +
' ' +
((__t = ( isShowBig ? 'big-show' : '' )) == null ? '' : __t) +
'">\r\n  <ul>\r\n    <li><a href="acticleDetail.html">\r\n        <div class="left">\r\n          <div class="small">' +
((__t = ( title )) == null ? '' : __t) +
'</div>\r\n          <div class="big">13</div>\r\n        </div>\r\n        <div class="right">\r\n            <div class="item-text">\r\n                <div class="title">田中信治：BLI/LCI在消化道表浅性肿瘤诊断中的临床价值</div>\r\n                <div class="date"><span>学术课堂</span><span>2020/10/20</span></div>\r\n                <div class="desc">本期讲座，吉田医师分别从以下方面讲解：；<br>\r\n                \r\n                  - 使用LCI和BLI提高大肠肿瘤辨识度；<br>\r\n                \r\n                  - LCI下30秒追加观察法；<br>\r\n                \r\n                  - 提高息肉发现率的武器—CAD EYE AI诊断系统</div>\r\n                <div class="tags">\r\n                  <div>\r\n                    <span>#早癌</span><span>#EUS</span><span>#超声内镜</span><span>#胰腺</span><span>#早癌</span><span>#EUS</span><span>#超声内镜</span><span>#胰腺</span>\r\n                  </div>\r\n                </div>\r\n            </div>\r\n        </div>\r\n      </a></li>\r\n    <li><a href="#">\r\n        <div class="left">\r\n          <div class="small">' +
((__t = ( title )) == null ? '' : __t) +
'</div>\r\n          <div class="big">12</div>\r\n        </div>\r\n        <div class="right">\r\n          <div class="item-text">\r\n            <div class="title">田中信治：BLI/LCI在消化道表浅性肿瘤诊断中的临床价值</div>\r\n            <div class="date"><span>学术课堂</span><span>2020/10/20</span></div>\r\n            <div class="desc">本期授课田中教授分别从大肠、胃、食管三个部位的早癌诊断，详细阐述了富士内镜所搭载的LCI和BLI在临床诊断上的价值，并强调基于NBI制定的...</div>\r\n            <div class="tags">\r\n              <div>\r\n                <span>#早癌</span><span>#EUS</span><span>#超声内镜</span><span>#胰腺</span><span>#早癌</span><span>#EUS</span><span>#超声内镜</span><span>#胰腺</span>\r\n              </div>\r\n            </div>\r\n          </div>\r\n        </div>\r\n      </a></li>\r\n    <li><a href="#">\r\n        <div class="left">\r\n          <div class="small">' +
((__t = ( title )) == null ? '' : __t) +
'</div>\r\n          <div class="big">11</div>\r\n        </div>\r\n        <div class="right">\r\n          <div class="item-text">\r\n            <div class="title">田中信治：BLI/LCI在消化道表浅性肿瘤诊断中的临床价值</div>\r\n            <div class="date"><span>学术课堂</span><span>2020/10/20</span></div>\r\n            <div class="desc">本期授课田中教授分别从大肠、胃、食管三个部位的早癌诊断，详细阐述了富士内镜所搭载的LCI和BLI在临床诊断上的价值，并强调基于NBI制定的...</div>\r\n            <div class="tags">\r\n              <div>\r\n                <span>#早癌</span><span>#EUS</span><span>#超声内镜</span><span>#胰腺</span><span>#早癌</span><span>#EUS</span><span>#超声内镜</span><span>#胰腺</span>\r\n              </div>\r\n            </div>\r\n          </div>\r\n        </div>\r\n      </a></li>\r\n    <li><a href="#">\r\n        <div class="left">\r\n          <div class="small">' +
((__t = ( title )) == null ? '' : __t) +
'</div>\r\n          <div class="big">10</div>\r\n        </div>\r\n        <div class="right">\r\n          <div class="item-text">\r\n            <div class="title">田中信治：BLI/LCI在消化道表浅性肿瘤诊断中的临床价值</div>\r\n            <div class="date"><span>学术课堂</span><span>2020/10/20</span></div>\r\n            <div class="desc">本期授课田中教授分别从大肠、胃、食管三个部位的早癌诊断，详细阐述了富士内镜所搭载的LCI和BLI在临床诊断上的价值，并强调基于NBI制定的...</div>\r\n            <div class="tags">\r\n              <div>\r\n                <span>#早癌</span><span>#EUS</span><span>#超声内镜</span><span>#胰腺</span><span>#早癌</span><span>#EUS</span><span>#超声内镜</span><span>#胰腺</span>\r\n              </div>\r\n            </div>\r\n          </div>\r\n        </div>\r\n      </a></li>\r\n    <li><a href="#">\r\n        <div class="left">\r\n          <div class="small">' +
((__t = ( title )) == null ? '' : __t) +
'</div>\r\n          <div class="big">09</div>\r\n        </div>\r\n        <div class="right">\r\n          <div class="item-text">\r\n            <div class="title">田中信治：BLI/LCI在消化道表浅性肿瘤诊断中的临床价值</div>\r\n            <div class="date"><span>学术课堂</span><span>2020/10/20</span></div>\r\n            <div class="desc">本期授课田中教授分别从大肠、胃、食管三个部位的早癌诊断，详细阐述了富士内镜所搭载的LCI和BLI在临床诊断上的价值，并强调基于NBI制定的...</div>\r\n            <div class="tags">\r\n              <div>\r\n                <span>#早癌</span><span>#EUS</span><span>#超声内镜</span><span>#胰腺</span><span>#早癌</span><span>#EUS</span><span>#超声内镜</span><span>#胰腺</span>\r\n              </div>\r\n            </div>\r\n          </div>\r\n        </div>\r\n      </a></li>\r\n    <li><a href="#">\r\n        <div class="left">\r\n          <div class="small">' +
((__t = ( title )) == null ? '' : __t) +
'</div>\r\n          <div class="big">08</div>\r\n        </div>\r\n        <div class="right">\r\n          <div class="item-text">\r\n            <div class="title">田中信治：BLI/LCI在消化道表浅性肿瘤诊断中的临床价值</div>\r\n            <div class="date"><span>学术课堂</span><span>2020/10/20</span></div>\r\n            <div class="desc">本期授课田中教授分别从大肠、胃、食管三个部位的早癌诊断，详细阐述了富士内镜所搭载的LCI和BLI在临床诊断上的价值，并强调基于NBI制定的...</div>\r\n            <div class="tags">\r\n              <div>\r\n                <span>#早癌</span><span>#EUS</span><span>#超声内镜</span><span>#胰腺</span><span>#早癌</span><span>#EUS</span><span>#超声内镜</span><span>#胰腺</span>\r\n              </div>\r\n            </div>\r\n          </div>\r\n        </div>\r\n      </a></li>\r\n  </ul>\r\n</div>\r\n';

}
return __p
}