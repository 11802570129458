import './index.scss'

//页面滚动监听
$(document).scroll(function () {
  let scroH = $(document).scrollTop(); //滚动高度
  let bannerH = $('.contest-header .banner').height(); //banner高度
  let headerH = $('.header-container').height(); //header高度

  // if (scroH >= bannerH) { // 距离大于banner+header的高度时，header上翻，比赛nav浮动
  //   $('.comp-root-header .header-container').css("transform", "translateY(-120%)")
  // } else {
  //   $('.comp-root-header .header-container').css("transform", "translateY(0)")
  // }
  if (scroH >= bannerH) { // 距离大于banner+header的高度时，header上翻，比赛nav浮动
    $('.contest-header .nav').addClass('fixed');
  } else {
    $('.contest-header .nav').removeClass('fixed');
  }
});

//点击登录，打开登录弹窗
$('[data-open="logindialog"]').on('click', function () {
  $('.login-dialog').fadeIn(200).css('display', 'flex')
  $('.login-dialog .login-cont').show()
  $('.login-dialog .wx-login-cont,.login-dialog .bind-tel').hide()
  $('.root-mask').fadeIn(200)

  //显示已登陆，仅用于效果查看
  $('.contest-header .user-icon').addClass('islogin')

})
